import { Autocomplete, Box, Grid, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import InputField from "../../../../components/Form/Input/InputField";
import LivisSwitch from "../../../../components/Switch/LivisSwitch";
import LivisCheckBox from "../../../../components/Form/Input/LivisCheckBox";
import LivisAutocomplete from "../../../../components/Form/Input/LivisAutocomplete";
import {
  setPermissionManagementData,
  setUserManagementButton,
} from "../../../../store/actions";
import { listAllApps } from "../../../../services/userManagement";

const PermissionDetails = (props: any) => {
  const { form } = props;
  const {
    register,
    setValue,
    getValues,
    control,
    watch,
    reset,
    formState: { errors },
  } = form;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [moduleList, setModuleList] = useState<any>([]);
  const [disableModuleSelection, setDisbaleModuleSelection] =
    useState<any>(false);
  const form_data = useSelector((state: any) => state.addPermissionReducer);
  const [selectedApp, setSelectedApp] = useState<any>({});

  const firstInputRef = useRef<any>(null);

  const validation_rule = {
    permission_name: {
      required: {
        value: true,
        message: t("Please enter Permission name"),
      },
      pattern: {
        value: /^[A-Za-z0-9_]+$/,
        message: t(
          "Permission name can only contain alphanumeric characters and underscores"
        ),
      },
    },
    permission_description: {
      required: {
        value: true,
        message: t("Please enter Description"),
      },
      minLength: {
        value: 2,
        message: t("Description must be at least 2 characters"),
      },
      maxLength: {
        value: 50,
        message: t("Description must be less than 50 characters"),
      },
    },
    app_id: {
      required: {
        value: true,
        message: t("Please select an App"),
      },
    },
  };

  /**
   * Updates the user management button state based on the presence of permission fields.
   * If form type is "edit_permission", checks permission name and description.
   * Otherwise, checks permission name, description, and app ID.
   */
  useEffect(() => {
    console.log(getValues(), props.formType);
    if (props.formType === "edit_permission") {
      if (
        getValues()?.permission_name?.length &&
        getValues()?.permission_description?.length
      ) {
        dispatch(setUserManagementButton(false));
      } else {
        dispatch(setUserManagementButton(true));
      }
    } else {
      if (
        getValues()?.permission_name?.length &&
        getValues()?.permission_description?.length &&
        getValues()?.app_id
      ) {
        dispatch(setUserManagementButton(false));
      } else {
        dispatch(setUserManagementButton(true));
      }
    }
  }, [watch, getValues(), form]);

  /**
   * Handles input field changes.
   *
   * @param e - The change event from the input field.
   */
  const handleChange = (e: any) => {
    const { name, value } = e.target;

    // Preserve existing errors
    const currentErrors = { ...errors };

    // Update the form data and reset the form
    const payload = {
      ...form_data,
      [name]: name === "permission_name" ? value?.trim() : value,
    };
    reset(payload);

    // Restore errors after reset
    Object.keys(currentErrors).forEach((errorField) => {
      if (currentErrors[errorField]) {
        form.setError(errorField, currentErrors[errorField]);
      }
    });

    // Dispatch updated data to Redux store
    dispatch(setPermissionManagementData(payload));
  };


  /**
   * Handles changes in the autocomplete component.
   *
   * @param e - The event object.
   * @param value - The selected autocomplete value.
   */
  const handleAutocompleteChange = (e: any, value: any) => {
    // Preserve existing errors
    const currentErrors = { ...errors };

    // Update the form data with the selected value
    const payload = {
      ...form_data,
      ["app_id"]: value?.id,
      ["app_name"]: value?.app_name,
    };

    // Update selected app
    setSelectedApp(value);

    // Reset the form data
    reset(payload);

    // Restore errors after reset
    Object.keys(currentErrors).forEach((errorField) => {
      if (currentErrors[errorField]) {
        form.setError(errorField, currentErrors[errorField]);
      }
    });

    // Dispatch updated data to Redux store
    dispatch(setPermissionManagementData(payload));
  };


  /**
   * Fetches user data on component mount and sets the module selection state
   * based on the form type. Disables module selection if form type is "edit_permission".
   */
  useEffect(() => {
    getUserData("all");
    if (props.formType === "edit_permission") {
      setDisbaleModuleSelection(true);
    } else {
      setDisbaleModuleSelection(false);
    }
  }, []);

  /**
   * Fetches user data based on the specified filter value and updates module list.
   *
   * @param value - The filter value for fetching user data.
   */
  const getUserData = async (value: string) => {
    let payload = {};
    payload = {
      is_active: value === "all" ? "" : value === "active" ? 1 : 0,
    };
    const response = await listAllApps(payload);
    const data = response?.data?.data?.app_list;
    // setUserData(data)
    // console.log(data)
    const output = data
      .filter((item: any) => item.is_active) // Filter only active items
      .map((item: any) => ({ value: item.id, label: item.app_name }));
    // console.log(output);
    setModuleList(data);
  };

  return (
    <div className="livis-add-station-wrap">
      <Grid container justifyContent={"center"}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {/* <h4 className="livis-stepper-title">{t("Permission Details")}</h4> */}
          <Box className="livis-part-data-stepper-margin">
            <Grid container spacing={3}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                {/* <LivisAutocomplete
                  name={`app_id`}
                  label={t("Select App")}
                  control={control}
                  rules={validation_rule['app_id']}
                  error={errors?.app?.message}
                  defaultValue={getValues()?.app_name}
                  options={moduleList}
                  livis_on_change={(e: any) => handleChange({ target: { name: "app_id", value: e?.value ? parseInt(e.value) : 0 } })}
                  disabled={disableModuleSelection}
                /> */}

                <Autocomplete
                  options={moduleList}
                  getOptionLabel={(option: any) => option.app_name || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.app_id === value.app_id
                  }
                  onChange={(event: any, value) => {
                    handleAutocompleteChange(event, value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select App"
                      variant="standard"
                      disabled={disableModuleSelection}
                    />
                  )}
                  disableClearable
                  disabled={disableModuleSelection}
                />
              </Grid>
            </Grid>
          </Box>

          <div className="livis-user-management-details-margin"></div>
          <h4 className="livis-stepper-title">{t("Permission Details")}</h4>

          <Box className="livis-part-data-stepper-margin">
            <Grid
              className="livis-inspection-station-add-form livis-recipe-container"
              container
              spacing={3}
            >
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Controller
                  name="permission_name"
                  control={control}
                  rules={validation_rule["permission_name"]}
                  render={({ field: { onChange, onBlur, value, name } }) => (
                    <>
                      <InputField
                        required={true}
                        id="permission_name"
                        name="permission_name"
                        label={t("Permission Name")}
                        type="text"
                        register={register}
                        rules={validation_rule["permission_name"]}
                        errors={errors}
                        livis_on_change={(e: any) => {
                          onChange(e);
                          handleChange(e);
                        }}
                        inputRef={firstInputRef}
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="livis-manage-data-import-preview-header-margin"
              >
                {t("Permission Name")} : &nbsp;
                {selectedApp?.app_name
                  ? selectedApp?.app_name + "." + form_data?.permission_name
                  : t("Select App")}
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Controller
                  name="permission_description"
                  control={control}
                  rules={validation_rule["permission_description"]}
                  render={({ field: { onChange, onBlur, value, name } }) => (
                    <>
                      <InputField
                        required={true}
                        id="permission_description"
                        name="permission_description"
                        label={t("Permission Description")}
                        type="text"
                        register={register}
                        rules={validation_rule["permission_description"]}
                        errors={errors}
                        livis_on_change={(e: any) => {
                          onChange(e);
                          handleChange(e);
                        }}
                      />
                    </>
                  )}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default PermissionDetails;
