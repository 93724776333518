import React, { useEffect } from "react";

const GoogleAnalytics = () => {
    /**
     * Initialize Google Tag Manager tracking code for production (livis.ai)
     */
    useEffect(() => {

        if (window.location.href.startsWith("https://livis.ai/")) {
            // Inject the GTM script dynamically
            const script1 = document.createElement("script");
            script1.async = true;
            script1.src = "https://www.googletagmanager.com/gtag/js?id=G-6TD2DJ53MM";

            const script2 = document.createElement("script");
            script2.innerHTML = `
                window.dataLayer = window.dataLayer || [];
                function gtag() { dataLayer.push(arguments); }
                gtag('js', new Date());
                gtag('config', 'G-6TD2DJ53MM');
            `;

            document.head.appendChild(script1);
            document.head.appendChild(script2);

            console.log("GTM script loaded for livis.ai in production");
        } else {
            console.log("GTM script not loaded (not in production or wrong URL)");
        }
    }, []);

    return null; // No UI elements needed
};

export default GoogleAnalytics;
